import { GoogleMap, Autocomplete, Marker, InfoWindow, useJsApiLoader, KmlLayer } from '@react-google-maps/api';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { IconButton, Loader, PropertyIdMapCard, PropertyMapModal } from 'components';
import { ICoordinate, ILocation } from 'models';
import useKmlVisibility from 'hooks/useKmlVisibility';

import { GoogleMapAddress, MapLocation, MapsPinProp } from './ExplorerMap.models';
import './ExplorerMap.styles.scss';
import { GOOGLE_MAP_LIBRARIES, REGION_MAP_DATASET_ID_MAPPING } from './ExplorerMap.constants';

const containerStyle = {
    width: '100%',
    height: '100%',
};

interface ExplorerMapProp {
    onChangeAddress?: (address?: GoogleMapAddress) => void;
    onChangeExplorerAddress?: (address: MapLocation) => void;
    isSearch: boolean;
    locationData?: ILocation;
    fullScreenView?: boolean;
    mapPins?: MapsPinProp[];
    coordinates?: ICoordinate;
    mapType?: string;
    showCategory1?: boolean;
    showLargeScaleSolar?: boolean;
    showMediumScaleSolar?: boolean;
    showPPC?: boolean;
    showCEJST?: boolean;
    showCoalClosure?: boolean;
    showMSA?: boolean;
}

export const ExplorerMap = ({
    onChangeAddress,
    isSearch,
    locationData,
    fullScreenView,
    mapPins,
    coordinates,
    mapType,
    onChangeExplorerAddress,
    showCategory1,
    showPPC,
    showCEJST,
    showCoalClosure,
    showMSA,
    showLargeScaleSolar,
    showMediumScaleSolar,
}: ExplorerMapProp) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_APIKEY}`,
        libraries: GOOGLE_MAP_LIBRARIES,
        mapIds: [REGION_MAP_DATASET_ID_MAPPING['SOUTHBRIDGE'].mapId],
        version: 'beta',
    });
    const [map, setMap] = useState<google.maps.Map>();
    const [center, setCenter] = useState<ICoordinate>({
        lat: 42.0751,
        lng: -72.0334,
    }); // Southbridge
    const kmlDefaultOptions = {
        preserveViewport: true,
        map: map,
    };
    const [zoom, setDefaultZoom] = useState(12);
    const autocomplete = useRef<google.maps.places.Autocomplete>();
    const [address, setAddress] = useState<GoogleMapAddress>();
    const [mapTypeId, setMapTypeId] = useState<string>('roadmap');
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [propertyMap, setPropertyMap] = useState(false);
    const [isMarkerClick, setIsMarkerClick] = useState(false);
    const [newLocationData, setNewLocationData] = useState<ILocation>({});
    const [initialValues] = useState({
        street: '',
        city: '',
        state: '',
        zip_code: '',
    });
    const [isShowBoundaries, setIsShowBoundaries] = useState(true); // enable boundaries by default
    const [mapKey, setMapKey] = useState(Math.random());

    const { visibleKmlUrls, handleBoundsChanged } = useKmlVisibility(
        map,
        isLoaded,
        showCoalClosure,
        showMSA,
        showCEJST,
        showPPC,
        showCategory1,
        showMediumScaleSolar,
        showLargeScaleSolar,
    );

    const onLoad = useCallback(
        (mapInstance: google.maps.Map) => {
            setMap(mapInstance);

            if (isShowBoundaries && mapInstance.getMapCapabilities().isDataDrivenStylingAvailable) {
                const dataset = mapInstance.getDatasetFeatureLayer(REGION_MAP_DATASET_ID_MAPPING['SOUTHBRIDGE'].datasetId);
                dataset.style = () => {
                    return {
                        strokeColor: '#d35400',
                        strokeOpacity: 0.8,
                        strokeWeight: 1.0,
                        fillColor: 'none',
                        fillOpacity: 0.2,
                    };
                };
            }
        },
        [isShowBoundaries],
    );

    const updateAddressOnExplorer = (event: string) => {
        if (event.length === 0) {
            onChangeExplorerAddress?.({});
        }
    };

    const getMapViewport = (viewport: google.maps.LatLngBoundsLiteral) => {
        const neLat = viewport.north;
        const neLng = viewport.east;
        const swLat = viewport.south;
        const swLng = viewport.west;

        return { neLat, neLng, swLat, swLng };
    };

    const onPlaceChanged = () => {
        if (map && autocomplete.current) {
            const place = autocomplete.current.getPlace();
            setAddress((prevAddress) => ({
                ...prevAddress,
                address: place,
            }));

            if (onChangeExplorerAddress && place.address_components && place.address_components.length > 0) {
                // viewport is used to get the boundaries of the map view. This prop is used for finding Utilites related to this geometry
                const viewport = place.geometry?.viewport?.toJSON();
                const location = place.geometry?.location?.toJSON();
                let addressQuery: MapLocation = {
                    name: place.name,
                    ...(viewport && location
                        ? {
                              map_viewport: getMapViewport(viewport),
                              position: {
                                  lat: location.lat,
                                  lng: location.lng,
                              },
                          }
                        : {}),
                };

                place.address_components.forEach((addressComponent) => {
                    if (addressComponent.types[0] === 'street_address') {
                        addressQuery = { ...addressQuery, street: addressComponent.long_name };
                    } else if (addressComponent.types[0] === 'administrative_area_level_1') {
                        addressQuery = { ...addressQuery, state: addressComponent.long_name };
                    } else if (addressComponent.types[0] === 'administrative_area_level_2') {
                        addressQuery = { ...addressQuery, county: addressComponent.long_name };
                    } else if (addressComponent.types[0] === 'locality') {
                        addressQuery = { ...addressQuery, city: addressComponent.long_name };
                    } else if (addressComponent.types[0] === 'postal_code') {
                        addressQuery = { ...addressQuery, zip_code: addressComponent.long_name };
                    }
                });

                onChangeExplorerAddress(addressQuery);
            }

            if (place.place_id) {
                const service = new window.google.maps.places.PlacesService(map);
                service.getDetails(
                    { placeId: place.place_id, fields: ['geometry'] },
                    (result: google.maps.places.PlaceResult | null, status: google.maps.places.PlacesServiceStatus) => {
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                            if (result?.geometry?.viewport && result?.geometry?.location) {
                                const boundaries = result.geometry.viewport;
                                setCenter(result.geometry.location.toJSON());
                                setAddress((prevAddress) => ({
                                    ...prevAddress,
                                    location: result?.geometry?.location?.toJSON(),
                                }));
                                map.fitBounds(boundaries);
                            }
                        }
                    },
                );
            }
        }
        setIsMarkerClick(false);
    };

    const onLoadAutocomplete = (autocompleteInstance: google.maps.places.Autocomplete) => {
        autocomplete.current = autocompleteInstance;
    };

    useMemo(() => {
        setNewLocationData({ ...initialValues, position: center });
    }, [center, initialValues]);

    const centerPosition = useMemo(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setCenter(userLocation);

                    if (locationData) {
                        setCenter(locationData.position as ICoordinate);
                    }

                    if (coordinates && Object.keys(coordinates).length !== 0) {
                        setCenter(coordinates);
                    }
                },
                () => {
                    // TODO: Error handling
                },
            );
        } else {
            // TODO: Geolocation is not supported by this browser
        }
    }, [locationData, coordinates]);

    const toggleDropdown = () => {
        setIsDropdownOpen((prev: boolean) => !prev);
    };

    const handleMapTypeChange = (value: string) => {
        map?.setMapTypeId(value);
        setMapTypeId(value);
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        if (centerPosition !== undefined) {
            setCenter(centerPosition);
        }
    }, [centerPosition]);

    useEffect(() => {
        if (isFullscreen && map) {
            map.setOptions({
                gestureHandling: 'greedy',
            });
        } else if (!isFullscreen && map) {
            map.setOptions({
                gestureHandling: 'cooperative',
            });
        }

        if (mapType !== undefined) {
            setMapTypeId(mapType);
        }
    }, [isFullscreen, map, mapType]);

    const toggleFullscreen = () => {
        setPropertyMap(true);
        setIsFullscreen(!isFullscreen);
    };

    useEffect(() => {
        onChangeAddress?.(address);
    }, [address, onChangeAddress]);

    const handleToggleIsShowBoundaries = useCallback(() => {
        setIsShowBoundaries((prev) => !prev);

        if (map) {
            setDefaultZoom(map.getZoom() as number);
        }
        setMapKey(Math.random());
    }, [map]);

    if (!isLoaded) {
        return <Loader />;
    }

    return (
        <>
            <div className={!isDropdownOpen ? 'main-div overlay-show' : 'main-div'}>
                {/* <Toggle
                    id='show-boundary'
                    label='Show boundaries on map'
                    value={isShowBoundaries}
                    onChange={handleToggleIsShowBoundaries}
                /> */}
                <GoogleMap
                    key={mapKey}
                    onLoad={onLoad}
                    mapContainerStyle={containerStyle}
                    onBoundsChanged={handleBoundsChanged}
                    zoom={zoom}
                    center={center}
                    onClick={() => setIsMarkerClick(false)}
                    tilt={0}
                    mapTypeId={google.maps.MapTypeId.HYBRID}
                    options={{
                        mapId: REGION_MAP_DATASET_ID_MAPPING['SOUTHBRIDGE'].mapId,
                        mapTypeId: mapTypeId !== 'roadmap' ? google.maps.MapTypeId.HYBRID : google.maps.MapTypeId.ROADMAP,
                        gestureHandling: 'cooperative',
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        rotateControl: false,
                        mapTypeControlOptions: {
                            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                            position: google.maps.ControlPosition.RIGHT_BOTTOM,
                        },
                        fullscreenControlOptions: {},
                        styles:
                            mapTypeId === 'roadmap'
                                ? [
                                      {
                                          elementType: 'geometry',
                                          stylers: [
                                              {
                                                  color: '#f5f5f5',
                                              },
                                          ],
                                      },
                                      {
                                          elementType: 'labels.icon',
                                          stylers: [
                                              {
                                                  visibility: 'off',
                                              },
                                          ],
                                      },
                                      {
                                          elementType: 'labels.text.fill',
                                          stylers: [
                                              {
                                                  color: '#616161',
                                              },
                                          ],
                                      },
                                      {
                                          elementType: 'labels.text.stroke',
                                          stylers: [
                                              {
                                                  color: '#f5f5f5',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'administrative.land_parcel',
                                          elementType: 'labels',
                                          stylers: [
                                              {
                                                  visibility: 'off',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'administrative.land_parcel',
                                          elementType: 'labels.text.fill',
                                          stylers: [
                                              {
                                                  color: '#bdbdbd',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'poi',
                                          elementType: 'geometry',
                                          stylers: [
                                              {
                                                  color: '#eeeeee',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'poi',
                                          elementType: 'labels.text.fill',
                                          stylers: [
                                              {
                                                  color: '#757575',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'poi.park',
                                          elementType: 'geometry',
                                          stylers: [
                                              {
                                                  color: '#e5e5e5',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'poi.park',
                                          elementType: 'labels.text.fill',
                                          stylers: [
                                              {
                                                  color: '#9e9e9e',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'road',
                                          elementType: 'geometry',
                                          stylers: [
                                              {
                                                  color: '#ffffff',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'road.arterial',
                                          elementType: 'labels.text.fill',
                                          stylers: [
                                              {
                                                  color: '#757575',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'road.highway',
                                          elementType: 'geometry',
                                          stylers: [
                                              {
                                                  color: '#dadada',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'road.highway',
                                          elementType: 'labels.text.fill',
                                          stylers: [
                                              {
                                                  color: '#616161',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'road.local',
                                          elementType: 'labels',
                                          stylers: [
                                              {
                                                  visibility: 'off',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'road.local',
                                          elementType: 'labels.text.fill',
                                          stylers: [
                                              {
                                                  color: '#9e9e9e',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'transit.line',
                                          elementType: 'geometry',
                                          stylers: [
                                              {
                                                  color: '#e5e5e5',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'transit.station',
                                          elementType: 'geometry',
                                          stylers: [
                                              {
                                                  color: '#eeeeee',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'water',
                                          elementType: 'geometry',
                                          stylers: [
                                              {
                                                  color: '#c9c9c9',
                                              },
                                          ],
                                      },
                                      {
                                          featureType: 'water',
                                          elementType: 'labels.text.fill',
                                          stylers: [
                                              {
                                                  color: '#9e9e9e',
                                              },
                                          ],
                                      },
                                  ]
                                : [
                                      {
                                          elementType: 'labels.icon',
                                          stylers: [{ visibility: 'on' }],
                                      },
                                  ],
                    }}
                >
                    {visibleKmlUrls.map((url, idx) => (
                        <KmlLayer key={idx} url={url} options={kmlDefaultOptions} />
                    ))}

                    <div id='custom-toggle-boundaries-control' className='custom-toggle-boundaries-control'>
                        <IconButton className='map-toggle-boundaries-icon' onClick={handleToggleIsShowBoundaries}>
                            <i className={isShowBoundaries ? 'fi fi-rr-eye' : 'fi fi-rr-eye-crossed'} />
                        </IconButton>
                    </div>
                    {fullScreenView && (
                        <div id='custom-fullscreen-control' className='custom-fullscreen-control'>
                            {!propertyMap && (
                                <IconButton className='map-fullscreen-icon' onClick={toggleFullscreen}>
                                    <img src='/assets/images/fullscreen.png' alt='fullscreen' />
                                </IconButton>
                            )}
                        </div>
                    )}
                    <div id='custom-map-control' className='custom-map-control'>
                        <IconButton className='map-control-icon' onClick={toggleDropdown}>
                            <img src='/assets/images/map_layer.svg' alt='Map Control Icon' />
                        </IconButton>
                        <div className={!isDropdownOpen ? 'map-type-dropdown dropdown-active' : 'map-type-dropdown'}>
                            <div className='map-type-label'>{mapTypeId === 'roadmap' ? 'Map view' : 'Satellite view'}</div>
                            <div className='map-type-inner'>
                                <button
                                    type='button'
                                    className={mapTypeId === 'roadmap' ? 'map-view-box active' : 'map-view-box'}
                                    onClick={() => handleMapTypeChange('roadmap')}
                                >
                                    <img src='/assets/images/roadmap.png' alt='Roadmap' />
                                </button>
                                <button
                                    type='button'
                                    className={mapTypeId === 'satellite' ? 'map-view-box active' : 'map-view-box'}
                                    onClick={() => handleMapTypeChange('satellite')}
                                >
                                    <img src='/assets/images/satellite.png' alt='Satellite' />
                                </button>
                            </div>
                        </div>
                    </div>
                    {mapPins &&
                        mapPins.length > 0 &&
                        mapPins.map((pin: MapsPinProp, index: number) => (
                            <Marker
                                key={index}
                                position={{ lat: pin.lat || 0, lng: pin.lng || 0 }}
                                title={pin.title}
                                animation={google.maps.Animation.DROP}
                            />
                        ))}
                    {isMarkerClick && (
                        <InfoWindow
                            position={{
                                lat: center.lat,
                                lng: center.lng,
                            }}
                        >
                            <PropertyIdMapCard locationData={newLocationData} />
                        </InfoWindow>
                    )}
                    {isSearch ? (
                        <div className='search-field-lg'>
                            <div className='search-field-inner-box'>
                                <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={onPlaceChanged} className='auto-complete'>
                                    <input
                                        type='text'
                                        placeholder='Enter property address'
                                        style={{
                                            boxSizing: 'border-box',
                                            outline: 'none',
                                            textOverflow: 'ellipses',
                                        }}
                                        onChange={(event) => updateAddressOnExplorer(event.target.value)}
                                    />
                                </Autocomplete>
                                <span className='search-icon'>
                                    <i className='fi fi-rr-search' />
                                </span>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </GoogleMap>
            </div>
            {propertyMap && (
                <PropertyMapModal
                    newLocationData={newLocationData}
                    show={propertyMap}
                    center={center}
                    mapTypeId={mapTypeId}
                    onClose={() => setPropertyMap(false)}
                    showCEJST={showCEJST}
                    showCoalClosure={showCoalClosure}
                    showMSA={showMSA}
                    showPPC={showPPC}
                />
            )}
        </>
    );
};

// https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/Retail_Service_Territories/FeatureServer/0/query?where=1%3D1&outFields=ID,NAME&geometry=-117.811%2C32.526%2C-116.633%2C32.930&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&returnGeometry=false&outSR=4326&f=json
// https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/Retail_Service_Territories/FeatureServer/0/query?where=1%3D1&outFields=ID,NAME&geometry=32.811%2C-117.128%2C32.808%2C-117.131&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&returnGeometry=false&outSR=4326&f=json
