import { KML_BUCKET_BASE_URL } from 'utils/constants';

export type KmlMappingType = {
    CoalClosure: {
        [state: string]: string[];
    };
    MSA_NMSA: {
        [state: string]: string[];
    };
    lowIncomeCEJST: {
        [state: string]: string[];
    };
    lowIncomePPC: {
        [state: string]: string[];
    };
    solarMedium: {
        [state: string]: string[];
    };
    solarLarge: {
        [state: string]: string[];
    };
    lowIncomeCategory1: {
        [state: string]: string[];
    };
};

export const kmlMapping: KmlMappingType = {
    CoalClosure: {
        Illinois: [`${KML_BUCKET_BASE_URL}/energy-community/CoalClosure/Illinois.kmz`],
        Massachusetts: [`${KML_BUCKET_BASE_URL}/energy-community/CoalClosure/Massachusetts.kmz`],
        Michigan: [`${KML_BUCKET_BASE_URL}/energy-community/CoalClosure/Michigan.kmz`],
        Ohio: [`${KML_BUCKET_BASE_URL}/energy-community/CoalClosure/Ohio.kmz`],
        California: [`${KML_BUCKET_BASE_URL}/energy-community/CoalClosure/California.kmz`],
    },
    MSA_NMSA: {
        Illinois: [`${KML_BUCKET_BASE_URL}/energy-community/MSA_NMSA/Illinois.kmz`],
        Massachusetts: [`${KML_BUCKET_BASE_URL}/energy-community/MSA_NMSA/Massachusetts.kmz`],
        Michigan: [`${KML_BUCKET_BASE_URL}/energy-community/MSA_NMSA/Michigan.kmz`],
        Ohio: [`${KML_BUCKET_BASE_URL}/energy-community/MSA_NMSA/Ohio.kmz`],
        California: [`${KML_BUCKET_BASE_URL}/energy-community/MSA_NMSA/California.kmz`],
    },
    lowIncomeCEJST: {
        Illinois: [`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCEJST/Illinois.kmz`],
        Massachusetts: [`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCEJST/Massachusetts.kmz`],
        Michigan: [`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCEJST/Michigan.kmz`],
        Ohio: [`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCEJST/Ohio.kmz`],
        California: Array.from({ length: 4 }, (_, i) => i + 1).map(
            (i) => `${KML_BUCKET_BASE_URL}/low-income/lowIncomeCEJST/California/California-${i}.kmz`,
        ),
    },
    lowIncomePPC: {
        Illinois: [`${KML_BUCKET_BASE_URL}/low-income/lowIncomePPC/IL.kmz`],
        Massachusetts: [`${KML_BUCKET_BASE_URL}/low-income/lowIncomePPC/MS.kmz`],
        Michigan: [`${KML_BUCKET_BASE_URL}/low-income/lowIncomePPC/MI.kmz`],
        Ohio: [`${KML_BUCKET_BASE_URL}/low-income/lowIncomePPC/OH.kmz`],
        Pennsylvania: [`${KML_BUCKET_BASE_URL}/low-income/lowIncomePPC/PA.kmz`],
    },

    lowIncomeCategory1: {
        Illinois: [
            `${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Illinois-1.kmz`,
            `${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Illinois-2.kmz`,
            `${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Illinois-3.kmz`,
        ],
        Massachusetts: [`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Massachusetts.kmz`],
        Michigan: [`${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Michigan.kmz`],
        Ohio: [
            `${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Ohio-1.kmz`,
            `${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Ohio-2.kmz`,
            `${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/Ohio-3.kmz`,
        ],
        California: Array.from({ length: 13 }, (_, i) => i + 1).map(
            (i) => `${KML_BUCKET_BASE_URL}/low-income/lowIncomeCategory1/California/California-${i}.kmz`,
        ),
    },
    solarMedium: {
        Southbridge: [`${KML_BUCKET_BASE_URL}/zoning-map/mediumScale/Southbridge.kmz`],
    },
    solarLarge: {
        Southbridge: [`${KML_BUCKET_BASE_URL}/zoning-map/largeScale/Southbridge.kmz`],
    },
};
