import styled from 'styled-components';

export const ElementTitle = styled.h5`
    margin-right: 8px;
`;

export const ElementHtmlWrapper = styled.div`
    margin-top: 4px;
`;

export const CloseButtonContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;
