import React, { ReactNode } from 'react';
import BootstrapModal from 'react-bootstrap/Modal';

interface ModalProps {
    show: boolean;
    onClose?: () => void;
    className?: string;
    title?: string;
    children: ReactNode;
    size?: 'sm' | 'lg' | 'xl';
    backdrop?: 'static' | true;
}

export const Modal = ({ show, onClose, className, title, children, size, backdrop = true }: ModalProps) => (
    <BootstrapModal backdrop={backdrop} show={show} size={size} onHide={onClose} centered className={`custom-modal ${className}`}>
        <BootstrapModal.Header>
            <BootstrapModal.Title className='text-bold-28 pb8 text-cool-grey-800 text-center w-100'>{title}</BootstrapModal.Title>
        </BootstrapModal.Header>
        {children}
    </BootstrapModal>
);
